var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "access"
  }, [_c('Row', {
    attrs: {
      "gutter": 10
    }
  }, [_c('Col', {
    staticStyle: {
      "margin": "0 0 10px 0"
    },
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 8
    }
  }, [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('Icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "md-contact"
    }
  }), _vm._v("当前用户 ")], 1), _c('div', {
    staticClass: "access-user-content access-current-user-content"
  }, [_vm.avatar ? _c('Avatar', {
    attrs: {
      "src": _vm.avatar,
      "size": "100"
    }
  }) : _c('Avatar', {
    attrs: {
      "icon": "md-person",
      "size": "100"
    }
  }), _c('p', [_vm._v("当前用户本页面拥有按钮权限:")]), _c('b', [_vm._v(_vm._s(_vm.permTypes))])], 1)])], 1), _c('Col', {
    staticStyle: {
      "margin": "0 0 10px 0"
    },
    attrs: {
      "lg": 24,
      "xl": 16
    }
  }, [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('Icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "md-apps"
    }
  }), _vm._v("当前用户本页面拥有的按钮操作 ")], 1), _c('Row', {
    staticClass: "access-user-content",
    attrs: {
      "gutter": 10,
      "align": "middle"
    }
  }, [_c('Col', {
    staticClass: "access-buttons",
    attrs: {
      "span": "6"
    }
  }, [_c('Button', {
    directives: [{
      name: "has",
      rawName: "v-has",
      value: 'add',
      expression: "'add'"
    }],
    attrs: {
      "type": "primary"
    }
  }, [_vm._v("添加按钮")]), _c('Button', {
    directives: [{
      name: "has",
      rawName: "v-has",
      value: 'edit',
      expression: "'edit'"
    }]
  }, [_vm._v("编辑按钮")]), _c('Button', {
    directives: [{
      name: "has",
      rawName: "v-has",
      value: 'delete',
      expression: "'delete'"
    }],
    attrs: {
      "type": "error"
    }
  }, [_vm._v("删除按钮")])], 1), _c('Col', {
    attrs: {
      "span": "18"
    }
  }, [_c('div', [_vm._v(" 您可以通过更换测试用户账号： "), _c('code', [_vm._v("test")]), _vm._v("或 "), _c('code', [_vm._v("test2")]), _vm._v(" 密码： "), _c('code', [_vm._v("123456")]), _vm._v("，然后观察该页面按钮以及部门相关数据权限的变化 "), _c('br'), _c('br'), _vm._v("自定义权限标签： "), _c('code', [_vm._v("v-has")]), _vm._v("，示例： "), _c('code', [_vm._v(_vm._s(_vm.example))]), _c('br'), _c('br'), _vm._v("说明：该Demo大部分页面为演示功能，前端未配置根据权限隐藏按钮 ")])])], 1)], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 10
    }
  }, [_c('Col', {
    staticStyle: {
      "margin": "0 0 10px 0"
    },
    attrs: {
      "sm": 24,
      "lg": 24,
      "xl": 14
    }
  }, [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('Icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "md-grid"
    }
  }), _vm._v("表格中权限判断 ")], 1), _c('div', {
    staticClass: "content-table"
  }, [_c('Alert', {
    attrs: {
      "show-icon": ""
    }
  }, [_vm._v(" iView Table组件已支持 slot-scope 用法，建议使用该新用法。 以下为Render函数中权限按钮示例 ")]), _c('Table', {
    attrs: {
      "border": "",
      "columns": _vm.columns,
      "data": _vm.data
    }
  })], 1)])], 1), _c('Col', {
    staticStyle: {
      "margin": "0 0 10px 0"
    },
    attrs: {
      "sm": 24,
      "lg": 24,
      "xl": 10
    }
  }, [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('Icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "md-contacts"
    }
  }), _vm._v("通过当前用户角色显示 ")], 1), _c('p', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_vm._v("无需配置，全局可用")]), _c('Row', {
    staticClass: "content-role"
  }, [_c('div', {
    staticClass: "access-user-roles"
  }, [_c('p', [_vm._v("当前用户拥有角色:")]), _c('b', [_vm._v(_vm._s(_vm.roles))])]), _c('div', {
    staticClass: "btns"
  }, [_c('Button', {
    directives: [{
      name: "hasRole",
      rawName: "v-hasRole",
      value: 'ROLE_USER',
      expression: "'ROLE_USER'"
    }],
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "primary"
    }
  }, [_vm._v("添加按钮")]), _c('Button', {
    directives: [{
      name: "hasRole",
      rawName: "v-hasRole",
      value: 'ROLE_TEST',
      expression: "'ROLE_TEST'"
    }],
    attrs: {
      "type": "error"
    }
  }, [_vm._v("删除按钮")]), _c('Button', {
    directives: [{
      name: "hasRole",
      rawName: "v-hasRole",
      value: 'ROLE_ADMIN',
      expression: "'ROLE_ADMIN'"
    }]
  }, [_vm._v("编辑按钮")])], 1), _c('div', {
    staticClass: "role-demo"
  }, [_vm._v(" 自定义角色权限标签： "), _c('code', [_vm._v("v-hasRole")]), _c('br'), _c('br'), _vm._v("示例： "), _c('code', [_vm._v(_vm._s(_vm.exampleRole))])])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };