export default {
  name: "access_index",
  data() {
    return {
      permTypes: [],
      roles: [],
      example: "<Button v-has=\"'add'\">添加按钮</Button>",
      exampleRole: "<Button v-hasRole=\"'ROLE_ADMIN'\">添加按钮</Button>",
      columns: [{
        title: "姓名",
        key: "name",
        render: (h, params) => {
          return h("div", [h("Icon", {
            props: {
              type: "ios-person"
            },
            style: {
              margin: "0 5px 0 0"
            }
          }), h("strong", params.row.name)]);
        }
      }, {
        title: "年龄",
        key: "age"
      }, {
        title: "地址",
        key: "address"
      }, {
        title: "操作",
        key: "action",
        width: 150,
        align: "center",
        render: (h, params) => {
          if (this.permTypes.includes("edit")) {
            return h("div", [h("Button", {
              props: {
                type: "primary",
                size: "small"
              },
              style: {
                marginRight: "5px"
              },
              on: {
                click: () => {}
              }
            }, "编辑"), h("Button", {
              props: {
                type: "error",
                size: "small"
              },
              on: {
                click: () => {}
              }
            }, "删除")]);
          } else {
            return h("div", [h("Button", {
              props: {
                type: "error",
                size: "small"
              },
              on: {
                click: () => {}
              }
            }, "删除")]);
          }
        }
      }],
      data: [{
        name: "John Brown",
        age: 18,
        address: "New York No. 1 Lake Park"
      }, {
        name: "Jim Green",
        age: 24,
        address: "London No. 1 Lake Park"
      }, {
        name: "Joe Black",
        age: 30,
        address: "Sydney No. 1 Lake Park"
      }]
    };
  },
  computed: {
    avatar() {
      return this.$store.state.user.avatar;
    }
  },
  methods: {
    initMeta() {
      if (this.$route.meta.permTypes) {
        this.permTypes = this.$route.meta.permTypes;
      }
    }
  },
  created() {
    this.initMeta();
    this.roles = this.getStore("roles");
  }
};